import { Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from './components/routes/PrivateRoute';
import { PublicRoute } from './components/routes/PublicRoute';
import { AppLayout } from './components/templates/AppLayout';
import { lazyWithRetry } from './helpers/lazyLoadModuleWithRetry';
import { AnonymousRoute } from './components/routes/AnonymousRoute';


const SignUpPage = lazyWithRetry(() => import('./components/pages/SignUp'));
const LogInPage = lazyWithRetry(() => import('./components/pages/LogIn'));
const DashboardPage = lazyWithRetry(() => import('./components/pages/Dashboard'));
const ProjectsPage = lazyWithRetry(() => import('./components/pages/Projects'));
const DataResourcesPage = lazyWithRetry(() => import('./components/pages/DataResources'));
const DataResourceDetails = lazyWithRetry(() => import('./components/pages/DataResourceDetails'));
const HelpResourcesPage = lazyWithRetry(() => import('./components/pages/HelpResources'));
const AddProjectPage = lazyWithRetry(() => import('./components/pages/AddProject'));
const EditProjectPage = lazyWithRetry(() => import('./components/pages/EditProject'));
const SettingsPage = lazyWithRetry(() => import('./components/pages/Settings'));
const ProjectDetailsPage = lazyWithRetry(() => import('./components/pages/ProjectDetails'));
const ForgotPasswordPage = lazyWithRetry(() => import('./components/pages/ForgotPassword'));
const PaymentPlanPage = lazyWithRetry(() => import('./components/pages/PaymentPlan'));
const PaymentSuccessPage = lazyWithRetry(() => import('./components/pages/PaymentSuccess'));
const SubscriptionExpiredPage = lazyWithRetry(() => import('./components/pages/SubscriptionExpired'));
const CompanyFormPage = lazyWithRetry(() => import('./components/pages/SignUp/CompanyForm'));
const InvitationRegistrationPage = lazyWithRetry(() => import('./components/pages/InvitationRegistration'));
const TermsAndConditionsPage = lazyWithRetry(() => import('./components/pages/TermsAndConditions'));
const EmailVerificationPage = lazyWithRetry(() => import('./components/pages/EmailVerification'));
const CompetitorsPricingPage = lazyWithRetry(() => import('./components/pages/CompetitorsPricing'));
const CompetitorsPricingDetailsPage = lazyWithRetry(() => import('./components/pages/CompetitorsPricingDetails'));
// Admin
const AdminPanelPage = lazyWithRetry(() => import('./components/pages/AdminPanel'));
const AdminUsers = lazyWithRetry(() => import('./components/pages/AdminUsers'));
const AdminCreateProjectPage = lazyWithRetry(() => import('./components/pages/AdminCreateProject'));
const AdminProvisionedProjectsPage = lazyWithRetry(() => import('./components/pages/AdminProvisionedProjects'));
const AdminLiveProjectsPage = lazyWithRetry(() => import('./components/pages/AdminLiveProjects'));
const AdminDataResourcesPage = lazyWithRetry(() => import('./components/pages/AdminDataResourcesPage'));

const routes = () => (
  <Route element={<AppLayout />}>
    <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
    <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
    <Route path="/projects" element={<PrivateRoute><ProjectsPage /></PrivateRoute>} />
    <Route path="/projects/:id" element={<PrivateRoute><ProjectDetailsPage /></PrivateRoute>} />
    <Route path="/project/share/:shareToken" element={<AnonymousRoute><ProjectDetailsPage /></AnonymousRoute>} />
    <Route path="/add-project" element={<PrivateRoute><AddProjectPage /></PrivateRoute>} />
    <Route path="/projects/:id/edit" element={<PrivateRoute><EditProjectPage /></PrivateRoute>} />
    <Route path="/help-resources" element={<PrivateRoute><HelpResourcesPage /></PrivateRoute>} />
    <Route path="/data-resources" element={<PrivateRoute><DataResourcesPage /></PrivateRoute>} />
    <Route path="/data-resources/:id" element={<PrivateRoute><DataResourceDetails /></PrivateRoute>} />
    <Route path="/competitors-pricing" element={<PrivateRoute><CompetitorsPricingPage /></PrivateRoute>} />
    <Route path="/competitors-pricing/:id" element={<PrivateRoute><CompetitorsPricingDetailsPage /></PrivateRoute>} />
    <Route path="/settings/*" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />

    <Route path="/sign-up" element={<SignUpPage />} />
    <Route path="/email-verification" element={<EmailVerificationPage />} />
    <Route path="/sign-up/company" element={<CompanyFormPage />} />
    <Route path="/login" element={<PublicRoute><LogInPage /></PublicRoute>} />
    <Route path="/forgot-password" element={<PublicRoute><ForgotPasswordPage /></PublicRoute>} />

    <Route path="/invitation-registration" element={<InvitationRegistrationPage />} />

    <Route path="/payment-plan" element={<PaymentPlanPage />} />
    <Route path="/payment-success" element={<PaymentSuccessPage />} />
    <Route path="/subscription-expired" element={<SubscriptionExpiredPage />} />

    {/* Admin */}
    <Route path="/admin/companies" element={<PrivateRoute isAdminRoute><AdminPanelPage /></PrivateRoute>} />
    <Route path="/admin/users" element={<PrivateRoute isAdminRoute><AdminUsers /></PrivateRoute>} />
    <Route
      path="/admin/data-resources"
      element={<PrivateRoute isAdminRoute><AdminDataResourcesPage /></PrivateRoute>}
    />
    <Route
      path="/admin/project/add"
      element={<PrivateRoute isAdminRoute><AdminCreateProjectPage /></PrivateRoute>}
    />
    <Route
      path="/admin/projects/live"
      element={<PrivateRoute isAdminRoute><AdminLiveProjectsPage /></PrivateRoute>}
    />
    <Route
      path="/admin/projects"
      element={<PrivateRoute isAdminRoute><AdminProvisionedProjectsPage /></PrivateRoute>}
    />
    <Route
      path="/admin/projects/:id"
      element={<PrivateRoute isAdminRoute><ProjectDetailsPage /></PrivateRoute>}
    />
    <Route
      path="/admin/projects/:id/edit"
      element={<PrivateRoute isAdminRoute><AdminCreateProjectPage /></PrivateRoute>}
    />
    <Route
      path="/admin/projects/:id/duplicate"
      element={<PrivateRoute isAdminRoute><AdminCreateProjectPage /></PrivateRoute>}
    />

    <Route path="*" element={<Navigate to="/dashboard" replace />} />
  </Route>
);

export default routes;
