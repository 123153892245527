import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import type {
  CreateUserParams, InvitedUser, IUser, UpdateUserParams,
} from '../types/user.types';


export const getUser = async (id: string): Promise<IUser> => {
  const url = `/user/${id}`;
  return axios.get(url);
};

export const getUserByEmail = async (email: string): Promise<IUser> => {
  const url = `/user/email/${email}`;
  return axios.get(url);
};

export const getCompanyUsers = async (status?: IUser['status']): Promise<IUser[]> => {
  const url = '/users/company';
  return axios.get(url, { params: { status } });
};

export const getSelf = async (config?: AxiosRequestConfig): Promise<IUser> => {
  const url = '/user/self';
  return axios.get(url, config);
};

export const createUser = (user: CreateUserParams): Promise<IUser> => {
  const url = '/user';
  return axios.post(url, user);
};

export const createInvitedUser = (user: InvitedUser): Promise<IUser> => {
  const url = '/user/invited';
  return axios.post(url, user);
};

export const updateUser = async (id: string, user: UpdateUserParams): Promise<IUser> => {
  const url = `/user/${id}`;
  return axios.patch(url, user);
};

export const disableUser = async (id: string): Promise<never> => {
  const url = `/user/${id}/disable`;

  return axios.patch(url);
};

export const getAvatarUploadPreSignedUrl = async (
  contentType: string
): Promise<{ preSignedUrl: string }> => {
  const encodedContentType = encodeURIComponent(contentType);
  const url = `/user/avatar/presigned-url?content-type=${encodedContentType}`;
  return axios.get(url);
};

export const deleteSelfUser = async () => {
  const url = '/user/self';
  return axios.delete(url);
};
