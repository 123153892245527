import {
  Suspense, useCallback, useEffect, useMemo, useState
} from 'react';
import type { FC } from 'react';
import { Layout } from 'antd';
import {
  Outlet, useLocation, useMatch, useNavigate
} from 'react-router-dom';
import { SideMenu } from '../../organisms/SideMenu';
import { Header } from '../../organisms/Header';
import { useAuth } from '../../../contexts/AuthContext';
import {
  StyledAppLayoutContent,
  StyledAppLayoutSider,
  StyledAppLayoutSiderLogoLink, StyledEmailVerificationAlert, StyledEmailVerificationAlertButton,
} from './AppLayout.styles';
import { ReactComponent as LogoWithoutText } from '../../../assets/icons/logo-without-text.svg';
import { Logo } from '../../atoms/Logo';
import { updateUser } from '../../../api/user';
import { OnBoardingTour } from '../../organisms/OnBoardingTour';
import { useIsSubscriptionActive } from '../../../hooks/useIsSubscriptionActive';
import useCheckTabletScreen from '../../../hooks/useTabletSize';
import { useAlert } from '../../../contexts/AlertContext';
import { Spinner } from '../../atoms/Spinner';
import ErrorBoundary from '../../../helpers/ErrorBoundary';


const blacklistedRoutes = [
  '/sign-up',
  '/sign-up/company',
  '/login',
  '/forgot-password',
  '/payment-plan',
  '/payment-success',
  '/invitation-registration',
  '/subscription-expired',
  '/terms-and-conditions',
  '/email-verification',
];

export const AppLayout: FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOnBoardingTourOpen, setIsOnBoardingTourOpen] = useState<boolean>(false);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] = useState(false);

  const isSharedProjectRouteMatch = useMatch('/project/share/:tokenId');
  const isPaymentSuccessRouteMatch = useMatch('/payment-success');

  const alert = useAlert();
  const {
    user,
    firebaseUser,
    refreshFirebaseUserState,
    isUserLoading,
    setUser,
    sendEmailVerification
  } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isTablet = useCheckTabletScreen();

  useEffect(() => {
    setIsCollapsed(isTablet);
  }, [isTablet]);

  useEffect(() => {
    if (location.pathname.match('/.*/$')) {
      // Remove trailing slash from the url
      const to = location.pathname.replace(/\/+$/, '');
      navigate(to, { replace: true });
    }
  }, [location.pathname, navigate]);

  const isSubscriptionActive = useIsSubscriptionActive();

  const onCollapse = useCallback((collapsed: boolean) => {
    setIsCollapsed(collapsed);
  }, []);

  const shouldRenderLayout = useMemo(() => {
    const isRouteBlacklisted = blacklistedRoutes.includes(location.pathname);
    return !isUserLoading && !isRouteBlacklisted && !isSharedProjectRouteMatch;
  }, [isSharedProjectRouteMatch, isUserLoading, location.pathname]);

  useEffect(() => {
    const isTourFinished = user?.isOnBoardingTourFinished === false;
    if (isTourFinished && isSubscriptionActive && !isPaymentSuccessRouteMatch) {
      navigate('/dashboard');
      setIsOnBoardingTourOpen(true);
    }
  }, [user, isSubscriptionActive, navigate, isPaymentSuccessRouteMatch]);

  const handleCloseOrFinishTour = async () => {
    setIsOnBoardingTourOpen(false);

    if (!user?._id) {
      return;
    }

    try {
      const updatedUser = await updateUser(user._id, { isOnBoardingTourFinished: true });
      setUser(updatedUser);
    } catch (massage) {
      console.log(massage);
    }
  };

  const pollFirebaseUserWithInterval = useCallback(() => {
    if (!firebaseUser) {
      return;
    }
    const MAX_RETRIES = 10;
    let currentTryCount = 0;

    const interval = setInterval(async () => {
      currentTryCount += 1;
      await firebaseUser.reload();

      if (firebaseUser.emailVerified || currentTryCount >= MAX_RETRIES) {
        clearInterval(interval);

        refreshFirebaseUserState();
      }
    }, 30000);
  }, [firebaseUser, refreshFirebaseUserState]);

  const sendVerificationEmail = useCallback(async () => {
    if (firebaseUser) {
      try {
        setIsSendingVerificationEmail(true);

        await sendEmailVerification('/login');

        setIsVerificationEmailSent(true);
        alert?.success('The verification email has been successfully sent!');

        //  Refresh firebase user every 30 seconds to hide the alert when email verification is done
        pollFirebaseUserWithInterval();
      } catch (err) {
        alert?.displayErrorAlert(err);
      } finally {
        setIsSendingVerificationEmail(false);
      }
    }
  }, [alert, firebaseUser, pollFirebaseUserWithInterval, sendEmailVerification]);

  const renderEmailVerificationAlert = useMemo(() => {
    if (!firebaseUser || firebaseUser.emailVerified) {
      return;
    }

    const message = isVerificationEmailSent ? (
      'Please follow the instruction in the email to complete the verification'
    ) : (
      <>
        Your email is not verified, please
        {' '}
        <StyledEmailVerificationAlertButton
          disabled={isSendingVerificationEmail}
          onClick={sendVerificationEmail}
        >
          click here
        </StyledEmailVerificationAlertButton>
        {' '}
        to send a verification email.
      </>
    );

    return (
      <StyledEmailVerificationAlert
        message={message}
        type="warning"
      />
    );
  }, [firebaseUser, isSendingVerificationEmail, isVerificationEmailSent, sendVerificationEmail]);

  return (
    <>
      {shouldRenderLayout ? (
        renderEmailVerificationAlert
      ) : null}

      {shouldRenderLayout ? (
        <Layout hasSider>
          <StyledAppLayoutSider
            collapsed={isCollapsed}
            collapsible={!isTablet}
            onCollapse={onCollapse}
            width={240}
          >
            <StyledAppLayoutSiderLogoLink to="/dashboard">
              {isCollapsed ? (
                <LogoWithoutText />
              ) : (
                <Logo height="40px" width="100%" />
              )}
            </StyledAppLayoutSiderLogoLink>

            <SideMenu />
          </StyledAppLayoutSider>

          <Layout>
            <Header />
            <StyledAppLayoutContent>
              <Suspense fallback={<Spinner />}>
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              </Suspense>
            </StyledAppLayoutContent>
          </Layout>

          <OnBoardingTour showOnBoardingTour={isOnBoardingTourOpen} onClose={handleCloseOrFinishTour} />
        </Layout>
      ) : (
        <Suspense fallback={<Spinner />}>
          <ErrorBoundary>
            {isSharedProjectRouteMatch ? (
              <Logo height="40px" width="100%" style={{ marginTop: 20 }} />
            ) : null}

            <Outlet />
          </ErrorBoundary>
        </Suspense>
      )}
    </>
  );
};
