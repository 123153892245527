import { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  StyledDate, StyledMarkAsRead, StyledNotification, StyledNotificationItemBottomBlock, StyledNotificationWrap
} from '../NotificationCenter/NotificationCenter.styles';
import { NotificationsType } from '../../../types/notifications.type';


interface NotificationItemProps {
  notification: NotificationsType;
  onMarkAsReadClick: (e) => void;
  onNotificationLinkClick: (notificationId: string) => void
}

dayjs.extend(relativeTime);
export const NotificationItem:FC<NotificationItemProps> = ({
  notification,
  onMarkAsReadClick,
  onNotificationLinkClick
}) => {
  const date = useMemo(() => {
    return dayjs().to(dayjs(notification?.date));
  }, [notification?.date]);

  return (
    <StyledNotification key={notification?.id}>
      <StyledNotificationWrap>
        <ReactMarkdown>{notification?.message}</ReactMarkdown>
        {notification.link ? (
          <Link to={notification.link} onClick={() => onNotificationLinkClick(notification.id)}>
            <ArrowRightOutlined />
          </Link>
        ) : null}
      </StyledNotificationWrap>

      <StyledNotificationItemBottomBlock>
        <StyledDate>{date}</StyledDate>
        {!notification?.isRead ? (
          <StyledMarkAsRead
            aria-hidden="true"
            onClick={(e) => onMarkAsReadClick(e)}
          >
            Mark as read
          </StyledMarkAsRead>
        ) : null}
      </StyledNotificationItemBottomBlock>
    </StyledNotification>
  );
};
