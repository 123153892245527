import { FC, ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';


const defaultRoute = '/admin/companies';
const allowedRoutes = [
  '/admin/companies',
  '/admin/users',
  '/admin/data-resources',
  '/admin/project/add',
  '/admin/projects',
  '/settings'
];

export const AdminRoute:FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();

  const isAllowedRoute = useMemo(() => {
    return allowedRoutes.find((route) => location.pathname.startsWith(route));
  }, [location]);

  if (!isAllowedRoute) {
    return <Navigate to={defaultRoute} />;
  }

  return <>{children}</>;
};
